(function(window, $) {
    'use strict';

    function OffCanvas(el) {
        this.el = el;
    }

    OffCanvas.prototype.init = function() {
        this.addListeners();
    };

    OffCanvas.prototype.fadeOut = function(element, duration) {
        element
            .transition({
                'opacity': 0,
            }, duration)
            .promise()
            .done(function() {
                this.hide();
            });
    };

    OffCanvas.prototype.fadeIn = function(element, displayState, duration) {
        element
        .css({ 'display': displayState })
            .transition({
                'display': displayState,
                'opacity': 1
            }, duration);
    };

    OffCanvas.prototype.addListeners = function() {
        var self = this;

        $('#nav-menu').click(function(e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $('html').toggleClass('openNav');
        });

        $('.nav-link').click(function(e) {
            e.preventDefault();
            self.fadeOut($('#navbar-off-canvas').find('.menu'), 500);

            $('#navbar-off-canvas')
            .transition({
                'width': '100%',
            }, 500, 'ease-in')
            .promise()
            .done(function() {
                self.transitionPage($(e.target).attr('href'));
            });
        });

        $('.page-crew').find('.thumbs-link').click(function(e) {
            e.preventDefault();
            $('html').transition({
                'opacity': 0
            }, 500)
                .promise()
                .done(function() {
                    self.transitionPage($(e.target).attr('href'));
                });
            
        });

        $('.page-friends').find('.thumbs-link').click(function(e) {
            e.preventDefault();
            $('html').transition({
                'opacity': 0
            }, 500)
                .promise()
                .done(function() {
                    self.transitionPage($(e.target).attr('href'));
                });
            
        });

        $('#book-a-seat').click(function(e) {
            e.preventDefault();
            
            $('#booking-off-canvas')
                .addClass('openBooking')
                .promise()
                .done(function() {
                    $('.booking-inner').transition({
                        'opacity': 1,
                        'delay': 1000
                    }, 1000);

                    self.fadeOut($('#header'), 1000);
                    self.fadeOut($('#footer'), 1000);
                });

            $('body').addClass('noscroll');
        });

        $('#show-map').click(function(e) {
            e.preventDefault();
            
            $('#map-off-canvas')
                .addClass('openMap')
                .promise()
                .done(function() {
                    $('.hoze-map').transition({
                        'opacity': 1,
                        'delay': 1000
                    }, 1000);

                    $('.map-txt').transition({
                        'opacity': 1,
                        'delay': 1000
                    }, 1000);

                    self.fadeOut($('#header'), 1000);
                    self.fadeOut($('#footer'), 1000);
                });

            $('body').addClass('noscroll');
        });

        $('#nav-arrow-corner-map').click(function(e) {
            e.preventDefault();
            
            if($('#map-off-canvas').hasClass('openMap')) {
                $('.hoze-map').transition({
                    'opacity': 0
                }, 1000);

                $('.map-txt')
                    .transition({
                        'opacity': 0
                    }, 1000)
                    .promise()
                    .done(function() {
                        self.fadeIn($('#header'), 'block', 1000);
                        self.fadeIn($('#footer'), 'block', 1000);
                        $('#map-off-canvas').removeClass('openMap')
                            .promise()
                            .done(function() {
                                $('#booking-off-canvas').css('dispay', 'none');
                            });
                    });
            }

            $('body').removeClass('noscroll');
        });

        $('#nav-arrow-corner-booking').click(function(e) {
            e.preventDefault();
            
            if($('#booking-off-canvas').hasClass('openBooking')) {
                $('.booking-inner')
                    .transition({
                        'opacity': 0,
                    }, 1000)
                    .promise()
                    .done(function() {
                        $('#booking-off-canvas').removeClass('openBooking');
                        $('#header').removeClass('openBooking');
                        $('#footer').removeClass('openBooking');
                        self.fadeIn($('#header'), 'block', 2000);
                        self.fadeIn($('#footer'), 'block', 2000);
                        $('#booking-off-canvas').removeClass('openBooking')
                            .promise()
                            .done(function() {
                                $('#booking-off-canvas').css('dispay', 'none');
                            });
                        
                    });

                $('body').removeClass('noscroll');
            }
            
            $('body').removeClass('noscroll');
        });
    };

    OffCanvas.prototype.transitionPage = function(pageLink) {
        window.location.href = pageLink;
    };

    window.OffCanvas = OffCanvas;
})(window, jQuery);